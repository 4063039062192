<template>
  <div id="app">
    <TopBar />
    <router-view></router-view>
    <CommonFooter />
  </div>
</template>

<script>
import TopBar from "@/components/TopBar";
import CommonFooter from "@/components/CommonFooter";

export default {
  name: "app",
  components: {
    TopBar,
    CommonFooter,
  },

  data() {},
};
</script>

<style></style>
