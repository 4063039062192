import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";

import { LoadingPlugin } from "vue-loading-overlay";
import "vue-loading-overlay/dist/css/index.css";
import "nprogress/nprogress.css";

createApp(App)
  .use(LoadingPlugin, { loader: "dots", color: "#355b7c" })
  .use(router)
  .mount("#app");
